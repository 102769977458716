import { account } from "../store/account.module";

const model = {
  Categories: "category",
  Leads: "lead",
  Locations: "location",
  Measures: "measure",
  Product: "product",
  Cities: "city",
  District: "district",
  Emailaddress: "emailaddress",
  Emailconfirmation: "emailconfirmation",
  Group: "group",
  Itemquestionchoices: "itemquestionchoices",
  Itemquestions: "itemquestions",
  Itemrecommendation: "itemrecommendation",
  Items: "items",
  Itemuservalues: "itemuservalues",
  Users: "user",
  Useritemselected: "useritemselected",
  Zipcode: "zipcode",
  Configuration: "configuration",
  "City Dashboard": "citydashboardleads",
  "City Overview": "citydashboardleads",
  Contacts: "contact",
  LocationPartners: "location",
  CityAdmin: "city",
  UserDetail: "user",
  UsersGroups: "group",
  CreateContact: "contact",
  Action: "action",
  Project: "project",
  Brand: "brand",
  Model: "model",
  Status: "status",
  PartnerCreate: "partner",
  Partner: "partner",
  "Partner Dashboard": "partner",
  Notes: "notes",
  SendQuote: "leadquotation",
  Quotation: "leadquotation",
  Orders: "order",
  LocationCities: "location",
  Employees: "location",
  LeadFee: "partnermeasurefees",
  Permission: "permission",
  LeadPartner: "leadpartner",
  LeadStatus: "leadstatusreminderdays",
  PartnerDetail: "partner",
  HousePassport: "housepassport",
  CityGroups: "citygroup",
  "Order Detail": "order",
  CalendarEvents: "outlookcalendarevents",
  TermsAndConditions: "termsandcondition",
  SpecialPartnerPDF: "specialpartnerpdf",
  ProformaBrand: "proformabrand",
  ProformaMeasure: "proformameasure",
  ProformaModel: "proformamodel",
  ProjectStatus: "projectstatus",
  Label: "label",
  EnergyReportDetail: "energyreport",
  EnergyReportForm: "energyreport",
  EnergyReports: "energyreport",
  TiptapEditor: "proformamodel",
  Tasks: "task",
  ArchivedTasks: "task"
};
const publicPages = [
  "Advisure Dashboard",
  "House Passport",
  "Configuration",
  "LeadDetail",
  "Quotation Webpage",
  "Overview",
  "Locations",
  "Advisures Form",
  "ProForma Quote",
  "Reviews",
  "Energy Reports",
];
// const publicPages = ['Locations', 'Cities', 'Users', 'Categories', 'Measures', 'Orders', 'Leads', 'Contacts', 'City Dashboard', 'House Passport', 'Partner Dashboard', 'Quotes'];
function checkTabs(modelName) {
  const group = localStorage.getItem("group");
  if ((modelName === "Locations" || modelName === 'Tasks') && ['Advisors','advisors'].includes(group)){
    return false;
  }

  // lead.view_lead_history permission to partners group shows to lead tab, below condition is to not show it
  if (modelName === "Leads" && ['Partners','partners'].includes(group)){
    return false;
  }

  if (modelName === "CreateEnergyReport"){
    return true;
  }
  if (modelName !== null && publicPages.indexOf(modelName) !== -1) {
    return true;
  }


  return modelName
    ? // eslint-disable-next-line no-prototype-builtins
      account.state.user.permissions.hasOwnProperty(model[modelName])
    : false;
}

function checkAction(modelName, action) {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // eslint-disable-next-line no-prototype-builtins
  if (account.state.user.permissions.hasOwnProperty(model[modelName])) {
    return permissions[model[modelName]].includes(action);
  }
  return false;
}

// eslint-disable-next-line import/prefer-default-export
export const checkPermissions = {
  checkTabs,
  checkAction,
  account,
};
